import {graphql} from '@apollo/client/react/hoc';
import gql from 'graphql-tag';

import queryUtils from "utils/queryUtils";

const RolesWithUsersQuery = gql`
  query RolesWithUsersQuery {
    rolesWithUsers {
      id
      name
      users {
        id
        name
        email
      }
    }
  }
`;


const GhostEmbedImagesQuery = gql`
  query GhostEmbedImagesQuery {
    ghostEmbedImages {
      id
      licenseId
      eventId
      scenarioId
      thumbnail
      events {
        id
        licenseId
        name
      }
      scenarios {
         id
         eventId
         name
      }
    }
  }
`;


export const licenseTemplateFields = `
  id
  name
  prefix
  durationMonths
  studentsLimit
  eventsLimit
  dataLimit
  comments
  catalogs {
    id
    name
  }
`;

const LicenseTemplatesQuery = gql`
  query LicenseTemplatesQuery {
    licenseTemplates {
      ${licenseTemplateFields}
    }
  }
`;


export const deviceFields = `
  id
  name
  nameMl
  description
  descriptionMl
  createdAt
  modifiedAt
  parameters
`;

const ServiceDevicesQuery = gql`
  query ServiceDevicesQuery {
    serviceDevices {
      ${deviceFields}
    }
  }
`;


const UsersQuery = gql`
  query UsersQuery($search: String!) {
    users(search: $search) {
      id
      name
      email
      talentId
      regionId
      active
      parameters
    }
  }
`;


const RegionsQuery = gql`
  query RegionsQuery {
    regions {
      id
      name
      nameMl
      code
    }
  }
`;


export const queryRolesWithUsers =
    graphql(RolesWithUsersQuery, {
        options: {
            fetchPolicy: 'network-only' },
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {rolesWithUsers: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {rolesWithUsers: data.error && data.error.message}),
            rolesWithUsers: data.rolesWithUsers
        })
    });


export const queryGhostEmbedImages =
    graphql(GhostEmbedImagesQuery, {
        options: {
            fetchPolicy: 'network-only' },
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {ghostEmbedImages: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {ghostEmbedImages: data.error && data.error.message}),
            ghostEmbedImages: data.ghostEmbedImages
        })
    });


export const queryLicenseTemplates =
    graphql(LicenseTemplatesQuery, {
        options: {
            fetchPolicy: 'network-only' },
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {licenseTemplates: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {licenseTemplates: data.error && data.error.message}),
            licenseTemplates: data.licenseTemplates
        })
    });


export const queryServiceDevices =
    graphql(ServiceDevicesQuery, {
        options: {
            fetchPolicy: 'network-only' },
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {serviceDevices: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {serviceDevices: data.error && data.error.message}),
            serviceDevices: data.serviceDevices
        })
    });


export const queryUsers =
    graphql(UsersQuery, {
        options: ({search}) => ({
            variables: {search: search},
            fetchPolicy: 'network-only' }),
        skip: ({search}) => !search,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {users: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {users: data.error && data.error.message}),
            users: data.users
        })
    });


export const queryRegions =
    graphql(RegionsQuery, {
        options: {
            fetchPolicy: 'network-only' },
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {regions: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {regions: data.error && data.error.message}),
            regions: data.regions
        })
    });