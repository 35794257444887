import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'

import {breadcrumbsStore} from 'appBase/TopNav'

import useSmartNavigate from 'hooks/useSmartNavigate'

import withUriParams from 'hocs/withUriParams'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import NamedBlock from 'components/blocks/NamedBlock'
import ListWithSort from 'components/blocks/ListWithSort'
import PanelModal from 'components/modals/PanelModal'
import {SERVICE_REGIONS_VIEW} from 'constants/constants'

import entityUtils from 'utils/entityUtils'

import {queryRegions} from '../data/serviceQueries'
import {createRegion, updateRegion, deleteRegion} from '../data/serviceMutations'
import {createMlJSONStringWithCurrentLanguage} from 'utils/translationUtils'


const RegionsView = compose(
     withUriParams([['regionId', Number]]),
     queryRegions,
     createRegion, updateRegion, deleteRegion,
     withGeneratorDataSource,
     withInitialDataLoadWaiting(['regions'])
)((props) => {

    const {regionId, regions, createRegion, updateRegion, deleteRegion,
           dataSource, setDataSource, saveAndReset, isChanged, hasErrors, onChange, onValidate} = props;
    const navigate = useSmartNavigate();
    const {t, i18n} = useTranslation();

    //region object for dataSource
    const regionSource = useMemo(() =>
         regionId && regions.find(region => region.id === regionId),
    [regionId, regions]);

    // setting dataSource
    useEffect(() => {
        setDataSource(
            regionSource,
            (data) => {
                data = entityUtils.filterFields(data, [
                    'id',
                    'nameMl',
                    'code'
                ]);
                updateRegion(data);
        });
    }, [regionSource]);

    // preparing links
    breadcrumbsStore.register(SERVICE_REGIONS_VIEW);
    useEffect(() => {
        breadcrumbsStore.set(SERVICE_REGIONS_VIEW, {name: t('appService.regions'), to: `${props.uri}`}, true);
    });

    //rows
    const rows = regions.map(region => ({
        fields: [
            region.id,
            region.code,
            region.name
        ],
        actions: {
            "select": () => navigate(`${props.uri}/${region.id}${props.location.search}`),
            "removeConfirmation": () => deleteRegion(region.id)
         }
    }));

    rows.push({
        fields: [t('appService.views.RegionsView.createNewRegion')],
        className: "text-end",
        notSelectable: true,
        actions: {
            "click": () => createRegion({
                nameMl: createMlJSONStringWithCurrentLanguage(t('appService.views.RegionsView.newRegion'), i18n)
            }).then(({data: {createRegion: { id }}}) => {
                navigate(`${props.uri}/${id}${props.location.search}`);
            })
        }
    });

    //onSave, onCancel
    const onSave = () => {
        saveAndReset();
        navigate(`${props.uri}${props.location.search}`);
    };
    const onCancel = () => {
        setDataSource(null);
        navigate(`${props.uri}${props.location.search}`);
    };

    return (
        <div className="d-flex flex-column h-100 w-100">
            <div className="flex-grow d-flex flex-column scroll-parent">
                <div className="flex-grow d-flex scroll-parent">
                    <div className="flex-grow scroll">
                        <h5 className="text-center mt-3">{t('appService.regions')}</h5>
                        <div className="container">
                            <NamedBlock>
                                <ListWithSort headers={[t('appService.views.RegionsView.id'),
                                                        t('appService.views.RegionsView.code'),
                                                        t('appService.views.RegionsView.name')]}
                                              sizes={["20*", "20*", "300*"]}
                                              rows={rows} />
                            </NamedBlock>

                            {dataSource &&
                            <PanelModal show title={t('appService.views.RegionsView.editRegion')} noPadding scrollable onClose={onCancel}>
                                <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors} onSave={onSave} onCancel={onCancel} />

                                <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                                    <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                                        items={[
                                        {
                                            type: "string",
                                            name: t('appService.views.RegionsView.name'),
                                            field: "nameMl",
                                            multilanguage: true,
                                            required: true
                                        },
                                        {
                                            type: "string",
                                            name: t('appService.views.RegionsView.code'),
                                            field: "code",
                                            minLength: 2,
                                            maxLength: 2
                                        }
                                        ]} />
                                </div>
                            </PanelModal> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default RegionsView;