import {graphql} from '@apollo/client/react/hoc';
import gql from 'graphql-tag';


const RegisterTalentId = gql`
  mutation registerTalentId($talentId: ID!) {
    registerTalentId(talentId: $talentId) {
      error
    }
  }
`;

const UnregisterTalentId = gql`
  mutation unregisterTalentId($talentId: ID!) {
    unregisterTalentId(talentId: $talentId) {
      error
    }
  }
`;

const ChangeRegion = gql`
  mutation changeRegion($data: ChangeRegionMutationInput!) {
    changeRegion(data: $data) {
      error
    }
  }
`;



export const registerTalentId = graphql(RegisterTalentId, {
    props: ({ mutate }) => ({ registerTalentId: (talentId) => mutate({ variables: {talentId} }) }),
    options: { refetchQueries: ['UserQuery']}
});

export const unregisterTalentId = graphql(UnregisterTalentId, {
    props: ({ mutate }) => ({ unregisterTalentId: (talentId) => mutate({ variables: {talentId} }) }),
    options: { refetchQueries: ['UserQuery']}
});

export const changeRegion = graphql(ChangeRegion, {
    props: ({ mutate }) => ({ changeRegion: (data) => mutate({ variables: {data} }) }),
    options: { refetchQueries: ['UserQuery']}
});
